import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Form  from 'react-bootstrap/Form';
import { Button, Col, Alert } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { CategoryHeader, TitledHeader, SolutionResults } from '.';
import $ from 'jquery';
import { post } from "react-restfully";
import {LoadingSpinner, UserContext, GetUser} from '.';

function Contact() {
    
    const user = (GetUser() || {}).user;
    const [first, setFirst] = useState(user?user.firstName:"" || "");
    const [last, setLast] = useState(user?user.lastName:""  || "");
    const [email, setEmail] = useState(user?user.email:""  || "");
    const [phone, setPhone] = useState(user?user.phone:""  || "");
    const [company, setCompany] = useState(user?user.organization:""  || "");
    const [message, setMessage] = useState("");
    
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");
    
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        setValidated(true);
  
        if (valid) {         
            let body =  {
                "first_name":  first, 
                "last_name":  last, 
                "email":  email, 
                "company":  company, 
                "phone":  phone, 
                "message":  message
            };
            console.dir(body)
            
            setSubmitStatus(false);
            setLoading(true);
            post('/api/contactus', {
                payload: body,
                headers: 
                    {
                       // "Authorization": "Bearer "+GetUser().jwttoken,
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {
                    setSubmitStatusState("success");
                    setSubmitStatus("Your message has been sent.");
                    setValidated(false);
                    setMessage("");
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <Alert variant={submitStatusState}>
                    {submitStatus}
                </Alert>
            );
        }
    }
    
    
    const navigate = useNavigate();
  return (
    <>
        <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
        <div className="contactus">
            <TitledHeader title="Contact Us" info=""></TitledHeader>
            <div className="body-content">
                <div className="container">
                    <div className="contactus-container">
                        <div className="contactus-form">
                            <Form noValidate validated={validated}  onSubmit={(evt) => {handleSubmit(evt);}}>
                                        {
                                            showAlert()
                                        }
                                <div className="row">
                                    <div className="mb-3 col-lg-6">
                                        <Form.Group controlId="formfirst">
                                            <Form.Control disabled={user!=null} required type="text" placeholder="First Name"
                                                        value={first} onChange={(e) => setFirst(e.target.value)}/>
                                            <Form.Control.Feedback type="invalid">Please provide your first name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    
                                    <div className="mb-3 col-lg-6">
                                        <Form.Group controlId="formlast">
                                            <Form.Control disabled={user!=null} required type="text" placeholder="Last Name" 
                                                        value={last} onChange={(e) => setLast(e.target.value)}/>
                                            <Form.Control.Feedback type="invalid">Please provide your last name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="mb-3 col-lg-6">
                                        <Form.Group controlId="formcompany">
                                            <Form.Control disabled={user!=null} required type="text"  placeholder="Company Name" 
                                                        value={company} onChange={(e) => setCompany(e.target.value)}/>
                                            <Form.Control.Feedback type="invalid">Please provide a company name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    
                                    <div className="mb-3 col-lg-6">
                                        <Form.Group controlId="formphone">
                                            <Form.Control type="tel" pattern="^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$" placeholder="Phone number" minLength="10"
                                                        value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                            <Form.Control.Feedback type="invalid">Please provide a contact phone number</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="mb-3 col-lg-6">
                                    <Form.Group controlId="formemail">
                                        <Form.Control disabled={user!=null} required type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email Address" 
                                                value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <Form.Control.Feedback type="invalid">Please provide a email address.</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                
                                <Form.Group controlId="formmessage" className="mb-3">
                                    <Form.Control required type="textareea" as="textarea" rows="6" placeholder="Message" minLength="10"
                                                        value={message} onChange={(e) => setMessage(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>
                                
                                <div className="contactus-button">
                                    <button className={"button-action"}>Submit</button>
                                </div>
                                
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Contact;
