import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form  from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import InputGroup  from 'react-bootstrap/InputGroup';
import { faChevronRight, faChevronDown, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import {ActiveClaims} from "..";


function ClaimSearch(props) {
    const sortOptions = [
        {name: "Amount", value: ["-billedCharges", "patientName"]},
        {name: "Expiration Date", value: ["expiringDateLong", "-expiringStatus", "patientName"]},
        {name: "Expiration Status", value: ["-expiringStatus", "expiringDateLong", "patientName"]},
        {name: "Patient Name", value: ["patientName"]},
        {name: "Provider", value: ["provider", "patientName"]},
        {name: "Status", value: ["status", "patientName"]},
    ];
    const [showExpired, setShowExpired] = useState(true);
    const [sortBy, setSortBy] = useState(sortOptions[1].name);
    const [sortByValue, setSortByValue] = useState(sortOptions[1].value);

    let searchText = props.text ?? "";
    const [searchClaimText, setSearchClaimText] = useState(props.text ?? "");
    const searchRef = React.createRef();
    const hint = props.SearchHint;

    const onSubmit = (e) => {
        e?.preventDefault();
        setSearchClaimText($(searchRef.current).val());
    }
    const toggleShowExpired = (evt) => {
        setShowExpired(evt.target.checked);
    }

    const onSort = (ekey, e) => {
        e?.preventDefault();
        for (let so of sortOptions) {
            if (so.name===ekey) {
                setSortByValue(so.value);
                setSortBy(ekey);
                break;
            }
        }
    }

  return (
    <>
        <div className="container search-header">                                              
            <Form className="category-search form-inline" onSubmit={(e) => {onSubmit(e);}} style={{display: "flex"}}>
                <Form.Group>
                    <InputGroup >
                            <InputGroup.Text className="searchPrefix">
                                <FontAwesomeIcon icon="search" />
                            </InputGroup.Text>
                        <Form.Control className="input-no-outline"
                            type="text"
                            placeholder={hint}
                            defaultValue={searchClaimText}
                            ref={searchRef}
                        />
                    </InputGroup>
                </Form.Group>
                <Button variant="repricer"  style={{marginLeft: "5px"}} onClick={(e) => {onSubmit(e);}}>Search</Button>

                <Dropdown onSelect={(ekey, e) => {onSort(ekey, e)}} style={{marginLeft: "10px"}}>
                    <Dropdown.Toggle className="" variant="repricer" id="dropdown-basic">
                        <div className="flex-row-container-nocol">
                            <div className="flex-row-fixed"> 
                                <span className="category-sortby-title">
                                    {sortBy}
                                </span> 
                            </div>
                            <div className="flex-row-fill right">  
                                <span className="">
                                    &nbsp;<FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                </span>
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            sortOptions.map( (sortOption, id) => {
                                return (<Dropdown.Item eventKey={sortOption.name} key={id}>{sortOption.name}</Dropdown.Item>);
                            })        
                        }
                    </Dropdown.Menu>
                </Dropdown>

                <Form.Group style={{marginLeft: "10px", marginTop: "auto", marginBottom: "auto"}}>
                    <Form.Check id="defaultBucket" label={"Show Expired"} checked={showExpired} onChange={toggleShowExpired}/>
                </Form.Group>

            </Form>
        </div>
        <div>
            <ActiveClaims sortBy={sortByValue} searchText={searchClaimText} showExpired={showExpired}></ActiveClaims>
        </div>
    </>
  );
}

//

export default ClaimSearch;
