import React from "react";
import { HomeCharts, PatientActionItems, ActiveClaims, ClaimSearch, AddWebkey } from "..";


import {
    useQuery,
    gql
  } from "@apollo/client";
  
function Home() {

  return (
    <>
        <div>
            <div className="body-content">
                <div className="container" style={{maxWidth:"max-content"}}>
                    <HomeCharts/>
                    <PatientActionItems/>

                    <AddWebkey></AddWebkey>
                    <ClaimSearch></ClaimSearch>
                    
                </div>
            </div>
        </div>
    </>
  );
}

//

export default Home;
