import React, { useState } from "react";
import { PatientActionItem } from "..";

import {
    useQuery,
    gql
  } from "@apollo/client";
  
function PatientActionItems(props) {
    const allPatients = props.allPatients || false;
    const dataName = allPatients?"allPatients":"patientsNeedingAction";
    const [resolvedIds, setResolvedIds] = useState([]);
    
    const PATIENTS_All = gql`
   {
    allPatients {
          id
          name
          active
          processingType
          calculationType
          patients {
            id
            firstName
            lastName
            payor
            bucketId
            active
          }
          buckets {
            id
            name
            minPercentage
            active
            canEdit
          }
        }
      }`;
      const PATIENTS_NEEDING_ACTION = gql`
     {
          patientsNeedingAction {
            id
            name
            active
            processingType
            calculationType
            patients {
              id
              firstName
              lastName
              payor
              bucketId
              active
            }
            buckets {
              id
              name
              minPercentage
              active
              canEdit
            }
          }
        }`;
    const { loading, error, data } = useQuery(allPatients?PATIENTS_All:PATIENTS_NEEDING_ACTION);
    
    if (error || loading) {
        return <></>;
    } 
    
    const onPatientUpdate = (patient) => {
        try {
            let newIds = JSON.parse(JSON.stringify(resolvedIds));
            newIds[patient.id]=patient.bucketId;
            setResolvedIds(newIds);
            
        } catch (e) {
            console.error(e);
        }
    }
    
    let providerPatients = []; 
    const src = data[dataName];
    for (let prov of src) {
        if (prov.patients && prov.patients.length) {
            for (let pat of prov.patients) {
                let isVis = allPatients || !resolvedIds[pat.id];
                
                if (isVis) {
                    let patient = JSON.parse(JSON.stringify(pat));
                    if (resolvedIds[pat.id]) {
                        patient.bucketId=resolvedIds[pat.id];
                    }
                    patient.provider = prov.name; 
                    patient.providerId = prov.id;
                    patient.buckets = prov.buckets;
                    providerPatients.push(patient);
                }
            }
        }
    }
    
    providerPatients.sort((a,b) => {
        let c = a.lastName.localeCompare(b.lastName);
        if (c==0) {
            c = a.firstName.localeCompare(b.firstName);
        }
    });
    
    if (providerPatients==null || providerPatients.length==0) {
        return <></>;
    }
    
  return (
    <div>
        <h3>Patient Action Items</h3>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Provider</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Payor</th>
                    <th scope="col">Class</th>
                </tr>
            </thead>
            <tbody>
                {
                    providerPatients.map((patient, id) => {
                        return (<PatientActionItem patient={patient} key={id} onUpdate={onPatientUpdate} updateBucketId={allPatients}/>);
                    })
                }
            </tbody>
        </table>
    </div>
  );
}

//

export default PatientActionItems;
