import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import { ClaimOfferComment, ImageOverlay } from "..";
import { ContactsOutlined } from "@material-ui/icons";

function ClaimOffer(props) {
  const offer = props.offer;
  const navigate = useNavigate();
  const [imageVisible, setImageVisible] = useState(false);

  const outputComments = () => {
    if (offer.comments && offer.comments.length) {
      return (
        <>
          {
            offer.comments.map((comment, id) => {
              return <ClaimOfferComment key={id} comment={comment}/>;
            })
          }
        </>
      )
    } else {
      return <></>
    }
  }

  const showImage = (evt) => {
    setImageVisible(true)
  }

  const hideImage = () => {
    setImageVisible(false)
  }

  const outputVisibleImage = () => {
    if (imageVisible) {
      console.log("outputing visible")
      let src = "data:image/png;base64, "+offer.confirmationImage;
      return (
        <>
          <ImageOverlay src={src} onClose={hideImage}></ImageOverlay>
        </>
      )
    }

    return <></>
  }

  const outputImage = () => {
    if (offer.confirmationImage && offer.confirmationImage.length) {
      return (<FontAwesomeIcon icon="image" style={{fontSize: "36px", color: "green", cursor: "pointer"}}  onClick={showImage}/>);
    }
    return <></>;
  }
  
  const outputAccepted = () => {
    if (offer.status==="Accepted") {
      return (
        <tr>
          <td>Accepted Offer</td>
          <td>
            <FormattedDate value={offer.acceptedDtm} month='numeric' day='numeric' year='numeric'/>&nbsp;
            <FormattedTime value={offer.acceptedDtm} hour="numeric" minute="numeric"/>
          </td>
          <td>${parseFloat(offer.amount).toFixed(2)}</td>
          <td>"Signature" {offer.acceptedSignature}</td>
          <td style={{textAlign: "center"}}>{outputImage()}</td>
        </tr>
      );
    }
    return <></>;
  }
  
  return (
    <>
      {outputVisibleImage()}
      {outputAccepted()}
      {outputComments()}
      <tr>
        <td>Offer</td>
        <td>
          <FormattedDate value={offer.offerDtm} month='numeric' day='numeric' year='numeric'/>&nbsp;
          <FormattedTime value={offer.offerDtm} hour="numeric" minute="numeric"/>
        </td>
        <td>${parseFloat(offer.amount).toFixed(2)}</td>
        <td>{offer.status}</td>
        <td style={{textAlign: "center"}}></td>
      </tr>

    </>
  );
}

//

export default ClaimOffer;
