import React from "react";

function TitledHeader(props) {
  return (
    <div className="hero-content repricer-gradient-primary">
        <div className="hero-content-padding hero-center">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <h1>{props.title}</h1>
                            <h4>{props.info || ''} </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
// inside of first div <div className="repricer-gradient-secondary-globe hero-content-padding"></div>

TitledHeader.propTypes = () => {
    return {
        title: React.string,
        title: React.info,
    };
}

export default TitledHeader;
