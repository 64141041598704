import React, {useState} from "react";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


function ImageOverlay(props) {
  const src = props.src;
  const onClose = props.onClose;

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }
  }
console.log("OUTPUTING LIGHTBOX")
  return (
    <>
          <Lightbox
            mainSrc={src}
            onCloseRequest={onCloseClick}
          />
    </>
  );
}

export default ImageOverlay;
