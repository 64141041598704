import React from "react";

function TestElement() {
  return (
    <div className="container">
        <h1>big headline</h1>
        <h2>big headline 2</h2>
        <h3>big headline 3</h3>
        <div>body text</div>
        <div className="quote">quote text</div>
        <div className="card" style={{width: 400, margin: 10, padding: 10}}><h1>test</h1></div>
        <button type="button" className="button-action">test</button>
        <button className="button-action activated">test activated</button>
        <button className="button-action inactive">test activated</button>
        <div className="separator"></div>
    </div>
  );
}

export default TestElement;
