import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Form  from 'react-bootstrap/Form';
import { Button, Col, Alert } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { CategoryHeader, TitledHeader, SolutionResults } from '..';
import $ from 'jquery';
import { post } from "react-restfully";
import {LoadingSpinner, UserContext, GetUser} from '..';
import Geosuggest from 'react-geosuggest';

function UserProfileForm() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");
    
    const user = GetUser().user;
    const [name, setName] = useState(user.name || "");
    const [email, setEmail] = useState(user.email || "");
    const [phone, setPhone] = useState(user.phone || "");
    
    let userContextLocal = null;
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
                
        setValidated(true);
  
        if (valid) {         
            let body =  {
                "name":  name, 
                "email":  email, 
                "phone":  phone, 
            };
            
            setSubmitStatus(false);
            setLoading(true);
            post('/api/secure/updateprofile', {
                payload: body,
                headers: 
                    {
                        "Authorization": "Bearer "+GetUser().jwttoken,
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {
                    const newUser = {
                        jwttoken: GetUser().jwttoken,
                        user: data.user
                    }
                    userContextLocal.setUser(newUser);
                    setName(newUser.user.name);
                    setPhone(newUser.user.phone);
                    
                    setSubmitStatusState("success");
                    setSubmitStatus("Your profile has been updated.");
                    setValidated(false);
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <Alert variant={submitStatusState}>
                    {submitStatus}
                </Alert>
            );
        }
    }
    
  return (
    <>
        <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
        <div className="myprofile">
            <TitledHeader title="My Profile" info=""></TitledHeader>
            <div className="body-content">
                <div className="container">
                    <div className="myprofile-container">
                        <div className="myprofile-form">
                            <UserContext.Consumer>
                            {
                                (userContext) => 
                                {
                                    userContextLocal = userContext;
                                    return (
                                        <Form noValidate validated={validated}  onSubmit={(evt) => {handleSubmit(evt);}}>
                                            {showAlert()}
                                            <div className="row">
                                                <div className="mb-3 col-12">
                                                    <Form.Group controlId="name">
                                                        <Form.Label className="body-bold">Name</Form.Label>
                                                        <Form.Control required type="text" placeholder="" value={name} onChange={e => setName(e.target.value )} minLength="10"/>
                                                        <Form.Control.Feedback type="invalid">Please provide your name.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="mb-3 col-lg-6">
                                                    <Form.Group controlId="email">
                                                        <Form.Label className="body-bold">Email</Form.Label>
                                                        <Form.Control disabled required type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="" value={user.email}/>
                                                        <Form.Control.Feedback type="invalid">Please provide a email address.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                
                                                <div className="mb-3 col-lg-6">
                                                    <Form.Group controlId="phone">
                                                        <Form.Label className="body-bold">Phone</Form.Label>
                                                        <Form.Control type="tel" pattern="^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$" minLength="10"  placeholder=""  
                                                            value={phone} onChange={e => setPhone(e.target.value )}/>
                                                        <Form.Control.Feedback type="invalid">Please provide a phone number.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            
                                            <div className="myprofile-button">
                                                <button className={"button-action"}>Update Profile</button>
                                            </div>
                                            
                                        </Form>
                                    )
                                }
                            }
                            </UserContext.Consumer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default UserProfileForm;
