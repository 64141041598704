import React from "react";

import { GetUser, ProviderSettings } from "..";

import {
    useQuery,
    gql
  } from "@apollo/client";
  
function Settings() {
    const user = GetUser();
    
    const MY_PROVIDERS = gql`
    {
        myProviders {
          id
          name
          active
          fullEmail
          einLastFour
          
          processingType
          calculationType
          defaultBucketName
          buckets {
            id
            name
            minPercentage
            active
            canEdit
          }
        }
      }`;
    const { loading, error, data, refetch } = useQuery(MY_PROVIDERS);
    
    let providers = [];
    if (error) {
        return <></>;
    } else if (loading) {
        return <></>;
    } 
    
    for (let prov of data.myProviders) {
        providers.push(JSON.parse(JSON.stringify(prov)));
    }
    
    providers.sort((a,b) => {
        return a.name.localeCompare(b.name);
    });
    
    const onProviderUpdate = (bucket, applyToAll, defaultBucket) => {
        if (applyToAll) {
            refetch();
        }
    }
    
  return (
    <>
        <div>
            <div className="body-content">
                <div className="container">
                    <p>The minimum offer percentage is defines the minimum offer amount that you will accept.</p>
                    <p>For example, if your billed amount is $1000, and your minimum offer percentage is 80%, the offer must be at least $800 for it to be accepted.</p>
                    <p>You will assign an insurance class to each patient.  Offers for each patient will be accepted or counter-offered based on the patient's insurance class.</p>
                    {
                        providers.map( (item, id) => {
                            return (<ProviderSettings provider={item} key={id} onUpdate={onProviderUpdate}/>);
                        })
                    }
                </div>
            </div>
        </div>
    </>
  );
}

//

export default Settings;
