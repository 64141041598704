
    export function sortByAttributes(data, sortbyList) {
        data.sort(function (a, b) {
            var c = 0;

            var s;
            var i = 0;
            while (c == 0 && i < sortbyList.length) {
                var sortbyDir = "asc";
                var sortby = sortbyList[i];
                if (sortby.startsWith("-")) {
                    sortby = sortby.substr(1);
                    sortbyDir = "desc";                    
                }

                var va = a[sortby];  if (va==undefined) va = "";
                var vb = b[sortby];  if (vb==undefined) vb = "";

                if (va && va.toLowerCase) {
                    va = va.toLowerCase();
                }
                if (vb && vb.toLowerCase) {
                    vb = vb.toLowerCase();
                }

                try {
                    let nv = parseFloat(va);
                    if (!isNaN(nv)) va=nv;
                } catch (e) {}
                try {
                    let nv = parseFloat(vb);
                    if (!isNaN(nv)) vb=nv;
                } catch (e) {}

                if (typeof(va)==='number' && typeof(vb)==='number') {
                    c = va - vb;
                } else if (typeof(va)==='number' && (vb===undefined || vb==='')) {
                    c = va;
                } else if (typeof(vb)==='number' && (va===undefined || va==='')) {
                    c = - vb;
                } else if ((va instanceof Date) || (vb instanceof Date)) {
                    c = (va?va.getTime():0) - (vb?vb.getTime():0);
                } else if (va && va.localeCompare != null) {
                    c = (va || "").localeCompare(vb || "");
                } else if (vb && vb.localeCompare != null) {
                    c = 0 - (vb || "").localeCompare(va || "");
                } else if (va==null && vb==null) {
                    c = 0;
                } else {
                    //c = va - vb; 
                    if (!isNaN(parseFloat(va)) && isFinite(va) && !isNaN(parseFloat(vb)) && isFinite(vb)) {
                        c = va - vb;
                    }
                }

                if (sortbyDir == "desc") {
                    c = 0 - c;
                }

                i++;
            }

            return c;
        });
    }