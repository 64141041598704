import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';

import { normalizeClaims } from "./NormalizeClaims.js";
import { ClaimOffer, LoadingSpinner } from "..";

import {
    useQuery,
    gql
  } from "@apollo/client";
  
function ViewClaim() {
  const {  id } = useParams();
  const navigate = useNavigate();
  
  const CLAIM = gql`
  query claim($id: Int!) {
    claim(id: $id) {
      id
      name
      active
      ein
      buckets {
        id
        name
        minPercentage
        active
        canEdit
      }
      claims {
          id
          dos
          dosLong
          multiplanPatient
          multiplanAccount
          multiplanClaim
          payor
          payorClaim
          multiplanContact
          multiplanPhone
          billedCharges
          initialOffer
          acceptedOffer
          status
          active
          createUser
          createDtmEpoch
          lastUpdateUser
          lastUpdateDtmEpoch
          webkey
          expiringStatus
          expiringDateLong
          latestOffer {
              id
              amount
              status
              offerDtmEpoch
              fee
              feeStatus
              acceptedDtmEpoch
              acceptedSignature
              rejectedDtmEpoch
              firstName
              lastName
              jobTitle
              phone
              emailAddress
              hasConfirmationImage
              confirmationImage
              comments {
                  id
                  counterAmount
                  counterType
                  status
                  commentDtmEpoch
                  sendCommentDtmEpoch
                  confirmationImage
              }
              latestComment {
                  id
                  counterAmount
                  counterType
                  status
                  commentDtmEpoch
                  sendCommentDtmEpoch
                  confirmationImage
              }
          }
          offers {
              id
              amount
              status
              offerDtmEpoch
              fee
              feeStatus
              acceptedDtmEpoch
              acceptedSignature
              rejectedDtmEpoch
              firstName
              lastName
              jobTitle
              phone
              emailAddress
              hasConfirmationImage
              confirmationImage
              comments {
                  id
                  counterAmount
                  counterType
                  status
                  commentDtmEpoch
                  sendCommentDtmEpoch
                  confirmationImage
              }
              latestComment {
                  id
                  counterAmount
                  counterType
                  status
                  commentDtmEpoch
                  sendCommentDtmEpoch
                  confirmationImage
              }
          }
          patient {
              id
              firstName
              lastName
              payor
              bucketId
              active
          }
      }
    }
  }`;
 
const { loading, error, data } = useQuery(
  CLAIM, 
      {variables: {"id": id}, nextFetchPolicy: "cache-first"}
  );
  if (loading) {
      return (            
          <LoadingSpinner active={true} text="test loader" className="loading-overlay"></LoadingSpinner>
      )
  }
if (error) {
  console.log(error);
  return <></>;
}

let claimsAndBuckets = normalizeClaims(data.claim);
    
let claim = claimsAndBuckets.claims[0];

const outputMPClaim = () => {
  if (claim.multiplanUrl && claim.multiplanUrl.length) {
      let url = claim.multiplanUrl

      return <>
          <a href={url} target="multiplan" style={{color:"green", whiteSpace: "nowrap"}}>
              <b>{claim.multiplanClaim}</b> <FontAwesomeIcon icon="square-arrow-up-right" />
          </a>
      </>
  } else {
      return <>{claim.multiplanClaim}</>
  }
}

const outputExpiringStatus = () => {
  if (claim.expiringDate!==null && claim.expiringDate.getTime()>0) {
        if (claim.expiringStatus && claim.expiringStatus.length) {
            return   (<div style={{textAlign: "center"}}>
                    <FormattedDate value={claim.expiringDate} month='numeric' day='numeric'/>&nbsp;
                    <FormattedTime value={claim.expiringDate} hour="numeric" minute="numeric"/><br/>
                    <FontAwesomeIcon style={{color: "red", fontSize: "28px"}} icon="triangle-exclamation" />
                </div>)
        } else {
            return   (<div style={{textAlign: "center"}}>
                    <FormattedDate value={claim.expiringDate} month='numeric' day='numeric'/>&nbsp;
                    <FormattedTime value={claim.expiringDate} hour="numeric" minute="numeric"/>
                </div>)
        }
    } else {
        if (claim.expiringStatus && claim.expiringStatus.length) {
            return  (<div style={{textAlign: "center"}}><FontAwesomeIcon style={{color: "red", fontSize: "28px"}} icon="triangle-exclamation" /></div>)
        }
        return <></>
    }
}
console.dir(claim)
  return (
    <>
        <div>
            <div className="body-content">
                <div className="container">
                  <h3>Claim Information</h3>
                  <table className="table w-auto">
                      <tbody>
                        <tr>
                          <td className="label">Expiring Status</td>
                          <td>{outputExpiringStatus()}</td>
                        </tr>
                        <tr>
                          <td>MP Claim</td>
                          <td>{outputMPClaim()}</td>
                        </tr>
                        <tr>
                          <td>Provider Account #</td>
                          <td>{claim.multiplanAccount}</td>
                        </tr>
                        <tr>
                          <td>Provider</td>
                          <td>{claim.provider}</td>
                        </tr>
                        <tr>
                          <td>Date of Service</td>
                          <td>{claim.dos}</td>
                        </tr>
                        <tr>
                          <td>Patient Name</td>
                          <td>{claim.patientName}</td>
                        </tr>
                        <tr>
                          <td>Payor</td>
                          <td>{claim.payor}</td>
                        </tr>
                        <tr>
                          <td>Import Date</td>
                          <td>
                            <FormattedDate value={claim.createDtm} month='short' day='numeric' year='numeric' hour="numeric" minute="numeric"/>
                          </td>
                        </tr>
                        <tr>
                          <td>Billed Amount</td>
                          <td>${parseFloat(claim.billedCharges).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Initial Offer</td>
                          <td>${parseFloat(claim.initialOffer).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Accepted Amount</td>
                          <td>{claim.acceptedOffer>0?"$"+parseFloat(claim.acceptedOffer).toFixed(2):""}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>{claim.status}</td>
                        </tr>
                      </tbody>
                  </table>
                  
                  <h3>Offers / Counters</h3>
                    <table className="table table-striped w-auto">
                      <thead>
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Created</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                          <th scope="col">Confirmation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                            claim.offers.map((offer, id) => {
                                return (<ClaimOffer key={id} offer={offer}></ClaimOffer>);
                            })
                        }
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  );
}

//

export default ViewClaim;
