import React, {useState} from "react";
import { useNavigate  } from "react-router-dom";
import { TitledHeader } from "../headers";
import Form  from 'react-bootstrap/Form';
import { Button, Col, Alert } from 'react-bootstrap';
import { TitledPageChange } from '..';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';
import { post } from "react-restfully";

function SignUp() {
    const recaptchaRef = React.createRef();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    
    
    const handleSubmit = (event) => {
        $("#formpassword2")[0].setCustomValidity("");
        
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        if (password !== password2) {
            $("#formpassword2")[0].setCustomValidity("Passwords do not match")
            valid = false;
        } else {
            $("#formpassword2")[0].setCustomValidity("")
        }
        
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue || recaptchaValue.trim().length===0) {
            $("#invalidRecaptcha").show()
        } else {
            $("#invalidRecaptcha").hide()
        }
        
        setValidated(true);
  
        if (valid) {         
            let body =  {
                "name":  name, 
                "email":  email, 
                "phone":  phone, 
                "password":  password
            };
            
            setSubmitStatus(false);
            setLoading(true);
            post('/api/sign-up', {
                payload: body,
                headers: 
                    {
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {                    
                    setSubmitStatusState("success");
                    setSubmitStatus("Your profile has been updated.");
                    setValidated(false);
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <Alert variant={submitStatusState}>
                    {submitStatus}
                </Alert>
            );
        }
    }
    
    const navigate = useNavigate();
    const clickLink = (url, evt) => {
        navigate(url);
        if (evt) {
            evt.preventDefault();
        }
    }
    const onChange = (value) => {
        console.log("Captcha value:", value);
        
    }
    
    const enterpriseName = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-name').replaceAll('"', '');
    const signInCover = "url("+getComputedStyle(document.documentElement).getPropertyValue('--signin-background')+")";
  return (
        <>
        <  div className="signin-cover" style={{backgroundImage: signInCover}}></div>
            <div className="filled">
                <div className="signin">
                    <div className="container">
                        <div className="signin-container">
                            <div className="signin-form">
                                <Form noValidate  validated={validated} onSubmit={(evt) => {handleSubmit(evt);}}>
                                    <h3>Join {enterpriseName}</h3>
                                    
                                    {showAlert()}
                                    <div className="row">
                                        <div className="mb-3 col-12">
                                            <Form.Group controlId="name">
                                                <Form.Label className="body-bold">Name</Form.Label>
                                                <Form.Control required type="text" placeholder="" value={name} onChange={e => setName(e.target.value )} minLength="10"/>
                                                <Form.Control.Feedback type="invalid">Please provide your name.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="mb-3 col-lg-6">
                                            <Form.Group controlId="email">
                                                <Form.Label className="body-bold">Email</Form.Label>
                                                <Form.Control  type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="" value={email} onChange={e => setEmail(e.target.value )}/>
                                                <Form.Control.Feedback type="invalid">Please provide a email address.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        
                                        <div className="mb-3 col-lg-6">
                                            <Form.Group controlId="phone">
                                                <Form.Label className="body-bold">Phone</Form.Label>
                                                <Form.Control type="tel" pattern="^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$" minLength="10"  placeholder=""  
                                                    value={phone} onChange={e => setPhone(e.target.value )}/>
                                                <Form.Control.Feedback type="invalid">Please provide a phone number.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="mb-3 col-12">
                                            <Form.Group controlId="formpassword">
                                                <Form.Label className="body-bold">Password</Form.Label>
                                                <Form.Control required type="password" placeholder="Password" minLength="6"
                                                    value={password} onChange={e => setPassword(e.target.value )}/>
                                                <Form.Control.Feedback type="invalid">Password must be at least 6 characters.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="mb-3 col-12">
                                            <Form.Group controlId="formpassword2">
                                                <Form.Label className="body-bold">Password (again)</Form.Label>
                                                <Form.Control type="password" placeholder=""
                                                    value={password2} onChange={e => setPassword2(e.target.value )}/>
                                                <Form.Control.Feedback type="invalid">Passwords must match.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>                                    
                                    
                                    <div className="row">
                                        <div className="mb-3 col-12">
                                            <Form.Group controlId="formrecaptcha" className="mb-1 recaptcha">
                                                <ReCAPTCHA sitekey="6LdEEuYUAAAAALdqNfl1dyv2RaLBv6eNertu3Zu7" onChange={onChange} ref={recaptchaRef}/>
                                                <Form.Control.Feedback type="invalid" id="invalidRecaptcha">You must complete the reCAPTCHA.</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="mb-3 caption">
                                            By clicking below, you agree to {enterpriseName} <a href="#" onClick={(evt) => clickLink("/terms-conditions", evt)}>Terms &amp; Conditions</a> and&nbsp;
                                            <a href="#" onClick={(evt) => clickLink("/privacy-policy", evt)}>Privacy Policy</a>
                                        </div>
                                    </div>
                                    
                                    <div className="myprofile-button">
                                        <button className={"button-action"}>Sign Up</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
}

export default SignUp;
