import React, { useState } from "react";
import { Button, Col } from 'react-bootstrap';
import { TitledHeader } from "../headers";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { LoadingSpinner } from '..';
import { UserContext } from '../../data/contexts'
import { post, get } from "react-restfully";
import useLocalStorage from 'react-use-localstorage';


const userKey = "reactUser";
let globalUser = null;
let componentNavigate = null;
export function GetUser() {
    return globalUser;
}

export function CheckUserAccess() {
    return true;
}

export function TestUserToken() {
    return new Promise( (resolve, reject) => {
        if (globalUser && globalUser.jwttoken) {            
            get('/api/secure/greeting', {
                headers: 
                    {
                        "Authorization": "Bearer "+globalUser.jwttoken
                    }
                
            }).then(data => {
                resolve(true);
            }).catch(err =>{
                if (window.location.pathname!="/users/sign_out")
                    componentNavigate("/users/sign_out");
                resolve(false);
            });
            
        } else {
            resolve(false);
        }
    });
    
}

function UserProvider(props) {
    componentNavigate = useNavigate();
    const maxAge = 60 * 60 * 24;
    const [storedUser, setStoredUser] = useLocalStorage(userKey, null);

    const setUser = (userIn) => {
        if (userIn===null) {
            console.log(" removing "+userKey);
            setStoredUser(null);
            globalUser = null;
        } else {
            localStorage.setItem(userKey, JSON.stringify(userIn));
            setStoredUser(JSON.stringify(userIn));
        }
    }
    
    const user = (storedUser && storedUser.length)?JSON.parse(storedUser):null;
    globalUser = user;
    
    React.useEffect(() => {
        TestUserToken();
    })

    return (
        <UserContext.Provider value={{user: user, setUser: setUser}}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserProvider;
