import React, {useState } from "react";

export const UserContext = React.createContext({
    user: null, 
    setUser: (user) => {}
});

export const AccessAllowedContext = React.createContext(true);

export const BrandingContext = React.createContext({
    title: "",
    setTitle: (title) => {},
    }
    
);
