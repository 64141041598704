import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate  } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn, faYoutube, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  const navigate = useNavigate();
  const clickLink = (url) => {
      navigate(url);
      //window.location.href=url
  }
  
  const enterpriseName = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-name').replaceAll('"', '');
  return (
      
    <div className="footer repricer-gradient-primary">  
        <div className="container">
            <div className="row footer-menu">
                <div className="col-sm-4">
                    <h4 className="footer-link-header">Why {enterpriseName}</h4>
                    <ul className="footer-links">
                        <li>
                        <a className="footer-link-href" onClick={() => {clickLink("/terms-conditions")}}>Terms and Conditions</a>
                        </li>
                        <li>
                        <a className="footer-link-href" onClick={() => {clickLink("/privacy-policy")}}>Privacy Policy</a>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-4">
                    <h4 className="footer-link-header">Company</h4>
                    <ul className="footer-links">                                            
                        <li>
                            <a className="footer-link-href" data-target="#modalContactForm" data-toggle="modal" onClick={() => {clickLink("/about")}}>About Us</a>
                        </li>
                        <li>
                            <a className="footer-link-href" data-target="#modalContactForm" data-toggle="modal" onClick={() => {clickLink("/contact-us")}}>Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-4">
                    <h4 className="footer-link-header">Policies</h4>
                    <ul className="footer-links">
                        <li>
                        <a className="footer-link-href" onClick={() => {clickLink("/terms-conditions")}}>Terms and Conditions</a>
                        </li>
                        <li>
                        <a className="footer-link-href" onClick={() => {clickLink("/privacy-policy")}}>Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Footer;
