
function toMid(ein, code, salt, mask) {
    var power = (salt % 10) + 4;
    var additive = parseInt(code) + salt;

    var obfus = additive;
    for (var i = 1; i < power; i++) {
        obfus = (obfus * additive) % mask;
    }

    let value = ein + obfus;
    while (value < 0) value += mask;
    value = value % mask;

    return value;
}

function getMultiplanURL(claim) {
    if (claim.webkey && claim.webkey.length && claim.ein) {
        var mask = 1000000000;
        var max = mask * mask;
        let salt = Math.floor(Math.random() * max, mask);
        let ein = claim.ein;
        let code = ein % 10000;
        let mid = toMid(ein, code, salt, mask);

        let url = "https://view.multiplan.com/Default/OnContinueClick?"
            + "webkey=" + claim.webkey
            + "&mid=" + mid
            + "&token=" + salt;

        return url;
    } else {
        return undefined;
    }
}

function getCommentStatus(comment) {
    comment.counterType = comment.counterType || "";
    if (comment.counterType.length) comment.counterType+=" ";

    if  (comment.status==="COUNTER_NEEDS_APPROVAL") return comment.counterType+"Counter Pending Approval";
    if  (comment.status==="COUNTER_PENDING") return comment.counterType+"Counter Pending";
    if  (comment.status==="COUNTERED") return comment.counterType+"Countered";
    if  (comment.status==="EXPIRED") return comment.counterType+"Expired";
    if  (comment.status==="APPROVED_MODIFIED") return comment.counterType+"Suggested (Unused)";

    return comment.counterType+comment.status;
}

function getOfferStatus(offer) {
    if  (offer.status==="OFFERED") return "Offered";
    if  (offer.status==="ACCEPT_NEEDS_APPROVAL") return "Accept Needs Approval";
    if  (offer.status==="ACCEPT_PENDING") return "Accept Pending";
    if  (offer.status==="ACCEPTED") return "Accepted";
    if  (offer.status==="COUNTER_PENDING") return "Counter Pending";
    if  (offer.status==="COUNTER_NEEDS_APPROVAL") return "Counter Needs Approval";
    if  (offer.status==="COUNTERED") return "Countered";
    if  (offer.status==="EXPIRED") return "Expired";

    return offer.status;
}

export function normalizeClaims(providerClaims) {
    let maxTime = (new Date(2100, 0, 1)).getTime();
    let claims = [];
    let buckets = {};
    for (let prov of providerClaims) {
        let usingBuckets = prov.calculationType==="Buckets";
        if (usingBuckets) {
            for (let bucket of prov.buckets) {
                buckets[bucket.id] = bucket;
            }
        }

        for (let rawClaim of prov.claims) {
            rawClaim = JSON.parse(JSON.stringify(rawClaim))

            if (rawClaim.createDtmEpoch) rawClaim.createDtm = new Date(parseInt(rawClaim.createDtmEpoch));
            if (rawClaim.lastUpdateDtmEpoch) rawClaim.lastUpdateDtm = new Date(parseInt(rawClaim.lastUpdateDtmEpoch));

            if (rawClaim.offers) {
                rawClaim.offers.sort((a, b) => {
                    return parseInt(b.offerDtmEpoch) - parseInt(a.offerDtmEpoch)
                })
            }
            for (let offer of rawClaim.offers) {
                if (offer.offerDtmEpoch) offer.offerDtm = new Date(parseInt(offer.offerDtmEpoch));
                if (offer.acceptedDtmEpoch) offer.acceptedDtm = new Date(parseInt(offer.acceptedDtmEpoch));
                if (offer.rejectedDtmEpoch) offer.rejectedDtm = new Date(parseInt(offer.rejectedDtmEpoch));

                offer.status = getOfferStatus(offer);
                if (offer.comments) {
                    (offer.comments).sort((a, b) => {
                        return parseInt(b.commentDtmEpoch) - parseInt(a.commentDtmEpoch)
                    })

                    for (let comment of offer.comments) {
                        if (comment.commentDtmEpoch) comment.commentDtm = new Date(parseInt(comment.commentDtmEpoch));
                        if (comment.sendCommentDtmEpoch) comment.sendCommentDtm = new Date(parseInt(comment.sendCommentDtmEpoch));
                        if (comment.sentCommentDtmEpoch) comment.sentCommentDtm = new Date(parseInt(comment.sentCommentDtmEpoch));

                        comment.status = getCommentStatus(comment);
                    }
                }
            }

            let claim = {};
            claim.createDtm = rawClaim.createDtm;
            claim.createDtm = rawClaim.lastUpdateDtm;
            claim.offers = rawClaim.offers;

            claim.providerId = prov.id;
            claim.provider = prov.name;
            claim.ein = parseInt(prov.ein);
            claim.id = rawClaim.id;
            claim.multiplanPatient = rawClaim.multiplanPatient;
            claim.multiplanAccount = rawClaim.multiplanAccount;
            claim.multiplanClaim = rawClaim.multiplanClaim;
            claim.payor = rawClaim.payor;
            if (claim.payor.indexOf("UnitedHealthcare") >= 0) claim.payor = "UnitedHealthcare";
            claim.payorClaim = rawClaim.payorClaim;
            claim.billedCharges = parseFloat(rawClaim.billedCharges).toFixed(2);
            claim.initialOffer = parseFloat(rawClaim.initialOffer).toFixed(2);
            claim.acceptedOffer = parseFloat(rawClaim.acceptedOffer).toFixed(2);
            claim.status = rawClaim.status;
            claim.webkey = rawClaim.webkey;
            claim.expiringStatus = rawClaim.expiringStatus;
            claim.expiringDate = new Date(0);
            claim.hasEmrClaimMatch = rawClaim.hasEmrClaimMatch;
            claim.minimumOffer = rawClaim.minimumOffer;
            claim.targetOffer = rawClaim.targetOffer;
            claim.calculationDateLong = rawClaim.calculationDateLong;
            
            if (claim.calculationDateLong && claim.calculationDateLong.length) {
                claim.calculationDate = new Date(parseInt (claim.calculationDateLong));
            }

            if (rawClaim.expiringDateLong && rawClaim.expiringDateLong.length) {
                claim.expiringDate = new Date(parseInt (rawClaim.expiringDateLong));
                claim.expiringDateLong = rawClaim.expiringDateLong;
            } else {
                claim.expiringDateLong = maxTime;
            }

            //let dparts = rawClaim.dos.split("/");
            claim.dos = rawClaim.dos;//new Date(dparts[2], dparts[0]-1, dparts[1]);

            claim.patientId = rawClaim.patient.id;
            claim.firstName = rawClaim.patient.firstName;
            claim.lastName = rawClaim.patient.lastName;
            claim.patientName = claim.lastName + ", " + claim.firstName;

            if (usingBuckets) {
                claim.bucketId = rawClaim.patient.bucketId;
    
                if (claim.bucketId) {
                    let b = buckets[claim.bucketId];
                    claim.bucketName = b.name + " (" + (100 * b.minPercentage) + "%)";
                    claim.bucketPercentage = b.minPercentage;
    
                    claim.minimumAccepted = parseFloat(claim.billedCharges * b.minPercentage).toFixed(2);
                } else {
                    claim.bucketName = "Not Assigned";
                    claim.bucketPercentage = 1;
                    claim.minimumAccepted = "";
                }
                claim.targetOffer = claim.minimumAccepted;
            } else {
                claim.minimumAccepted = claim.minimumOffer ?? claim.billedCharges;
                claim.targetOffer = claim.targetOffer ?? claim.billedCharges;

                if (claim.hasEmrClaimMatch) {                    
                    claim.bucketName = "Matched to EMR";
                } else {                                        
                    claim.bucketName = "Not Matched";
                }
            }

            let maxOffer = null;
            if (!rawClaim.offers || rawClaim.offers.length === 0) {
                rawClaim.offers = [];
                if (rawClaim.latestOffer) {
                    rawClaim.offers.push(rawClaim.latestOffer);
                }
            }
            if (rawClaim.offers) {
                for (let offer of rawClaim.offers) {
                    if (maxOffer == null || offer.amount > maxOffer.amount) {
                        maxOffer = offer;
                    }
                }
            }
            claim.offerId = (maxOffer ? maxOffer.id : undefined);
            claim.offer = rawClaim.latestOffer ?? maxOffer;
            claim.offerAmount = maxOffer.amount;
            claim.latestCounter = null;
            if (maxOffer.status.toUpperCase().indexOf("COUNTER") >= 0 && maxOffer.latestComment) {
                claim.latestCounter = maxOffer.latestComment;
                claim.latestCounterAmount = parseFloat(claim.latestCounter.counterAmount).toFixed(2);
            }

            claim.canAccept = true;
            switch (claim.status) {
                case "NEW":
                    claim.status = "New";
                    break;
                case "COUNTER_NEEDS_APPROVAL":
                    claim.status = "Counter Needs Approval";
                    claim.counterNeedsApproval = true;
                    break;
                case "COUNTER_PENDING":
                    claim.status = "Counter Pending"
                    break;
                case "COUNTERED":
                    claim.status = "Countered";
                    break;
                case "ACCEPT_NEEDS_APPROVAL":
                    claim.status = "Accept Needs Approval";
                    claim.acceptNeedsApproval = true;
                    break;
                case "ACCEPT_PENDING":
                    claim.status = "Accept Pending"
                    claim.canAccept = false;
                    break;
                case "ACCEPTED":
                    claim.status = "Accepted"
                    claim.canAccept = false;
                    break;
                case "EXPIRED":
                    claim.status = "Expired"
                    claim.canAccept = false;
                    break;
                default:
                    break;
            }

            claim.multiplanUrl = getMultiplanURL(claim);

            claims.push(claim);
        }
    }

    return {
        claims: claims,
        buckets: buckets
    }
}