import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import Search from '@material-ui/icons/Search';

import { UserContext } from '../../data/contexts'
import { GetUser } from "..";

function Navigation(props) {
    const navigate = useNavigate();

    const clickLink = (url, evt) => {
        if (evt && evt.stopPropagation) {
            evt.stopPropagation()
        }
        navigate(url);
        document.dispatchEvent(new MouseEvent('click'));
        //window.location.href=url
    }

    let variant = props.variant || 'repricer';

    const enterpriseName = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-name').replaceAll('"', '');
    const logoUrl = getComputedStyle(document.documentElement).getPropertyValue('--logo-top-img').replaceAll('"', '');

    const iconButton = () => {
        let user = GetUser();
        if (user) {
            if (user.user.imageUrl) {
                return (<div><div className="icon-btn profile"><img src={user.user.imageUrl} /></div></div>);
            } else {
                let names = user.user.name.split(" ");
                let initials = "";
                for (let name of names) {
                    if (name.length) {
                        initials += name.substring(0,1);
                    }
                }
                return (<div><div className="icon-btn profile initials">{initials}</div></div>);
            }
        }
    };

    const signInClick = () => {
        localStorage.setItem("signin_from", window.location.pathname);
        clickLink("/users/sign_in")
    }

    return (
        <Navbar bg="repricer" variant={variant} collapseOnSelect expand="lg" >
            <Navbar.Brand onClick={() => { clickLink("/") }}>
                <img
                    src={logoUrl}
                    className="repricer-logo"
                    alt={enterpriseName} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="justify-content-end" bsPrefix="repricer-navbar-toggler">
                <FontAwesomeIcon icon={faBars} fixedWidth className="" />
            </Navbar.Toggle>
            <Navbar.Collapse id="justify-content-end">
                <Nav className="justify-content-end" style={{ width: "100%" }}>


                    <UserContext.Consumer>
                        {
                            (userContext) => {
                                if (userContext.user) {
                                    return (<div className="header-right-side">
                                        
                                    {
                                        (userContext && userContext.user)?(<Nav.Link onClick={() => {clickLink("/")}}>Dashboard</Nav.Link>) : <></>
                                    }
                                        
                                    {
                                        (userContext && userContext.user)?(<Nav.Link onClick={() => {clickLink("/patients")}}>Patients</Nav.Link>) : <></>
                                    }
                                    
                                    {
                                        (userContext && userContext.user)?(<Nav.Link onClick={() => {clickLink("/settings")}}>Settings</Nav.Link>) : <></>
                                    }
                                                                          
                                    {
                                        (userContext && userContext.user)? (<div className="header-divider"></div>) : <></>
                                    }

                                        <NavDropdown title={iconButton()} className="header-icon-dropdown">

                                            <NavDropdown.Item onClick={(evt) => { clickLink("/users/profile", evt); }}>My Profile</NavDropdown.Item>
                                            <div className="dropdown-separator"></div>
                                            <NavDropdown.Item onClick={(evt) => { clickLink("/users/edit", evt); }}>Change Password</NavDropdown.Item>
                                            <div className="dropdown-separator"></div>
                                            <NavDropdown.Item onClick={(evt) => { clickLink("/users/sign_out", evt); }}>Sign Out</NavDropdown.Item>
                                        </NavDropdown>
                                    </div>)
                                } else {
                                    return (
                                        <div>
                                            <button className={"button-action-bright activated bg-repricer"} onClick={() => { signInClick() }}>Login</button>
                                        </div>
                                    )
                                }
                            }
                        }
                    </UserContext.Consumer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
