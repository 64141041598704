import React, {useState, useContext} from "react";
import { Button, Col } from 'react-bootstrap';
import { TitledHeader } from "../headers";
import Form  from 'react-bootstrap/Form';
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useNavigate  } from "react-router-dom";

function Blank() {
    const navigate = useNavigate();
    const signInCover = "url("+getComputedStyle(document.documentElement).getPropertyValue('--signin-background')+")";
  return (
    <>
        <div className="filled">
            <div className="signin-cover" style={{backgroundImage: signInCover}}></div>
            <div className="info-dlg">
                <div className="container">
                    <div className="info-dlg-container">
                        <div className="info-dlg-body">
                                <h3>Not Implemented</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Blank;
