import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
import queryString from 'query-string';

import "./themes/repricer.scss";
import "./themes/bootstrap.scss";

import "./themes/repricer-banner.scss";
import "./themes/repricer-contactus.scss";
import "./themes/repricer-flex.scss";
import "./themes/repricer-footer.scss";
import "./themes/repricer-hero.scss";
import "./themes/repricer-info-dlg.scss";
import "./themes/repricer-loading.scss";
import "./themes/repricer-myprofile.scss";
import "./themes/repricer-signin.scss";
import "./themes/repricer-search-filters.scss";
import "./themes/shimmer.scss";

import "./index.scss";

import { IntlProvider } from 'react-intl';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    useQuery,
    gql
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Router, Route, Routes, Switch } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faTriangleExclamation, faSquareArrowUpRight, faImage } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'

import {
    ScrollToTop, About, Contact, TestElements,
    Navigation, Footer, NotFound, Blank,
    UserProvider, SignOut, GetUser, TestUserToken, ChangePassword,
    TermsConditions, PrivacyPolicy, 
    UserProfileForm, ResetPassword,
    SignIn, SignUp, ForgotPassword,
    Home, Settings, UserContext, Patients, ViewClaim
} from "./components";
import { BrandingContext, AccessAllowedContext } from './data/contexts';
import messages_en from "./translations/en.json";


library.add(faSearch, faBell, faTriangleExclamation, faSquareArrowUpRight, faImage)

function WrappedNavPage(props) { 
    const enterpriseName = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-name').replaceAll('"', '');
    const [pageTitle, setPageTitle] = useState(enterpriseName);

    const defaultOptions = {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all',
        },
    }
    
    const httpLink = createHttpLink({
        uri: '/api/graphql',
    });
  
    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const user = GetUser();
        // return the headers to the context so httpLink can read them
        let userHeaders = {};
        
        if (user && user.jwttoken) {
            userHeaders = {
                Authorization: 'Bearer '+user.jwttoken
            }   
        }
        
        return {
            headers: {
            ...headers,
            ...userHeaders
            }
        }
    });
  
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: defaultOptions
    });

    document.title = pageTitle;

    let messages = messages_en;
    const language = navigator.language.split(/[-_]/)[0];
    if (language === "en") {
        messages = messages_en;
    } else {
    }
    
    TestUserToken();
        
        return (
        <BrowserRouter>
                <ApolloProvider client={client}>
                    <IntlProvider messages={messages} locale={language}>
                        <BrandingContext.Provider value={{
                            title: pageTitle, setTitle: setPageTitle
                        }}>
                            <UserProvider>
                                <div className="repricer-site">
                                        <Navigation />
                                        <div className="site-body">
                                            <ScrollToTop>
                                                <UserContext.Consumer>
                                                {
                                                    (userContext) => 
                                                    {
                                                        return (   
                                                            <AccessAllowedContext.Consumer>
                                                            {
                                                                (accessAllowed) => 
                                                                {
                                                                    if (userContext.user && accessAllowed) {
                                                                        return (
                                                                            <Routes>
                                                                                <Route path="/" element={<Home />} />
                                                                                <Route path="/claim/:id" element={<ViewClaim />} />
                                                                                
                                                                                <Route path="/users/sign_in" element={<SignIn />} />
                                                                                <Route path="/users/sign_out" element={<SignOut />} />
                                                                                <Route path="/users/sign_up" element={<SignUp />} />
                                                                                <Route path="/users/edit" element={<ChangePassword />} />
                                                                                <Route path="/users/password/new" element={<ForgotPassword />} />
                                                                                <Route path="/users/password/edit" element={<ResetPassword />} />
                                                                                <Route path="/users/profile" element={<UserProfileForm />} />
                                                                                
                                                                                <Route path="/patients" element={<Patients />} />
                                                                                <Route path="/settings" element={<Settings />} />
                                                                                
                                                                                <Route path="/test" element={<TestElements />} />
                            
                                                                                <Route path="/terms-conditions" element={<TermsConditions />} />
                                                                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            
                                                                                <Route path="/about-us" element={<About />} />
                                                                                <Route path="/team" element={<Blank />} />
                                                                                <Route path="/contact-us" element={<Contact />} />
                            
                                                                                <Route path="/notfound" element={<NotFound />} />
                            
                                                                                <Route path="*" element={<NotFound />} />
                                                                            </Routes>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <Routes>
                                                                                <Route path="*" element={<SignIn />} />
                                                                            </Routes>
                                                                        )
                                                                    }
                                                                }
                                                            }                                                
                                                        </AccessAllowedContext.Consumer>
                                                        )
                                                    }
                                                }
                                                </UserContext.Consumer>
                                            </ScrollToTop>
                                        </div>
                                        <Footer />
                                </div>
                            </UserProvider>
                        </BrandingContext.Provider>
                    </IntlProvider>
                </ApolloProvider>
        </BrowserRouter>
    );
}

ReactDOM.render(
    <WrappedNavPage></WrappedNavPage>,
    document.getElementById("root")
);

serviceWorker.unregister();
