import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

import { LoadingSpinner, GetUser } from "..";
import { post } from "react-restfully";

import {
    useQuery,
    gql
} from "@apollo/client";

function BucketSettings(props) {
    const onUpdate = props.onUpdate;
    const [showDialog, setShowDialog] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");

    const bucket = props.bucket;
    const defaultBucketName = props.defaultBucketName;
    
    console.log("BUCKETSETTINGS DEF BUCKET: "+defaultBucketName+"   "+(defaultBucketName===bucket.name));
    
    const [minPercentage, setMinPercentage] = useState(bucket.minPercentage * 100);
    const [applyToAll, setApplyToAll] = useState(false);
    const [defaultBucket, setDefaultBucket] = useState(defaultBucketName===bucket.name);

    const editPercentage = (evt) => {
        handleShow();
    }
    const handleClose = () => {
        setShowDialog(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        /*if (!applyToAll && bucket.minPercentage==minPercentage/100) {
            handleClose();
            return;
        }*/
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        setValidated(true);
  
        if (valid) {       
            let url = applyToAll?"/api/secure/update-buckets":"/api/secure/update-bucket";
            let body =  {
                id: applyToAll?-1:bucket.id,
                minPercentage:  minPercentage/100,
                defaultBucket: defaultBucket,
                name: bucket.name
            };
            
            setSubmitStatus(false);
            setLoading(true);
            post(url, {
                payload: body,
                headers: 
                    {
                        "Authorization": "Bearer "+GetUser().jwttoken,
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                try {
                    bucket.minPercentage = minPercentage/100;
                } catch (e) {
                    console.error(e);
                }
                
                if (data.status===0) {
                    if (onUpdate!=null) {
                        onUpdate(bucket, applyToAll, bucket.name);
                    }
                    
                    setValidated(false);
                    handleClose();
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    const handleShow = () => {
        setSubmitStatus(false);
        setValidated(false);
        setApplyToAll(false);
        setDefaultBucket(defaultBucketName===bucket.name);
        setMinPercentage(bucket.minPercentage * 100);
        setShowDialog(true);
    }


    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <div>
                    <Alert variant={submitStatusState}>
                        {submitStatus}
                    </Alert>                    
                </div>
            );
        }
    }
    
    const toggleApplyToAll = (evt) => {
        setApplyToAll(evt.target.checked);
    }
    
    const toggleDefaultBucket = (evt) => {
        setDefaultBucket(evt.target.checked);
    }

   // if (!bucket.canEdit) return <></>

    return (
        <>
            <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
            <tr>
                <td>{bucket.name}</td>
                <td>{bucket.minPercentage*100}% <a className="pencil-btn" onClick={editPercentage}><FontAwesomeIcon icon={faPencil} /></a></td>
            </tr>

            <Modal show={showDialog} onHide={handleClose} className="repricer-site site-body">
                <Modal.Header closeButton>
                    <Modal.Title><b>{bucket.name} - Insurance Class</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {showAlert()}

                        <Form.Group controlId="form.email" className="mb-3">
                            <Form.Label className="body-bold">Minimum Offer Percentage</Form.Label>
                            <Form.Control placeholder="" required  style={{textAlign: "right"}}
                                type="number" min="1" max="99" disabled={!bucket.canEdit}
                                value={minPercentage} onChange={(e) => setMinPercentage(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please provide a percentage between 1 and 99.</Form.Control.Feedback>
                        </Form.Group>

                        <div className="float-left">
                            <Form.Check id="defaultBucket" label={"Default Insurance Class for Provider"} checked={defaultBucket} onChange={toggleDefaultBucket}/>
                        </div>
                        
                        <div className="float-left">
                            <Form.Check id="applyToAll" label={"Apply to all providers for "+bucket.name} checked={applyToAll} onChange={toggleApplyToAll}/>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

//

export default BucketSettings;
