import React, {useState} from "react";
import { TitledHeader } from "../headers";
import { Button, Col, Alert } from 'react-bootstrap';
import Form  from 'react-bootstrap/Form';
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useNavigate  } from "react-router-dom";
import { LoadingSpinner } from '..';
import { post } from "react-restfully";

function ForgotPassword() {
    const navigate = useNavigate();
    
    const resetPasswordUri = `${window.location.origin}/users/password/edit`;
    const [email, setEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");
    
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
                
        setValidated(true);
  
        console.log("VALID: "+valid)
        if (valid) {         
            let body =  {
                "email": email,
                "url": resetPasswordUri
            };
            
            setSubmitStatus(false);
            setLoading(true);
            post('/api/forgotpassword', {
                payload: body,
                headers: 
                    {
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {
                    setSubmitStatusState("success");
                    setSubmitStatus("Password reset instructions have been sent.");
                    setValidated(false);
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <Alert variant={submitStatusState}>
                    {submitStatus}
                </Alert>
            );
        }
    }
    
    const clickLink = (url) => {
        navigate(url);
        //window.location.href=url
    }
    
    const signInCover = "url("+getComputedStyle(document.documentElement).getPropertyValue('--signin-background')+")";
  return (
    <>
        <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
        <div className="signin-cover" style={{backgroundImage: signInCover}}></div>
        <div className="filled">
            <div className="signin">
                <div className="container">
                    <div className="signin-container">
                        <div className="signin-form">
                            <Form noValidate validated={validated} onSubmit={(evt) => {handleSubmit(evt);}}>
                                {showAlert()}
                                <h3>Forgot your password?</h3>
                                
                                <Form.Group controlId="form.email" className="mb-3">
                                    <Form.Control placeholder="Email Address" required
                                        type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">Please provide a email address.</Form.Control.Feedback>
                                </Form.Group>
                                
                                <div className="mb-3">
                                    <button className={"button-action"}>Reset Password</button>
                                </div>
                                
                                <div className="mb-2">
                                    Already have an account? <a href="#" onClick={ () => {clickLink("/users/sign_in")}}>Sign In</a>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default ForgotPassword;
