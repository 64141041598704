import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

import { LoadingSpinner, GetUser } from "..";
import { post } from "react-restfully";

import {
    useQuery,
    gql
} from "@apollo/client";

function PatientActionItem(props) {
    const onUpdate = props.onUpdate;
    const updateBucketId = props.updateBucketId || false;
    const [showDialog, setShowDialog] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const patient = props.patient;
    const [bucketId, setBucketId] = useState(patient.bucketId);

    const handleSubmit = (newBucketId) => {
        let body =  {
            id: patient.id,
            bucketId: newBucketId
        };
        
        setLoading(true);
        post("/api/secure/update-patient", {
            payload: body,
            headers: 
                {
                    "Authorization": "Bearer "+GetUser().jwttoken,
                    "Content-Type": "application/json"
                }
        }).then(data => {
            if (data.status===0) {
                patient.bucketId=parseInt(newBucketId);
                if (onUpdate!=null) {
                    onUpdate(patient);
                } 
                
                if (updateBucketId) {
                    setBucketId(newBucketId);
                }
            } else {
                window.alert("Error updating patient");
            }
            
            setLoading(false);
        }).catch(err =>{
            window.alert("Error updating patient");
            setLoading(false);
        });
    };

    const onBucketChange = (evt) => {
        if (evt.target.value>0 && evt.target.value!=bucketId) {
            handleSubmit(evt.target.value);
            //setBucketId(evt.target.value);
        }
    }
    return (
        <>
            <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
            <tr>
                <td>{patient.provider}</td>
                <td>{patient.firstName}</td>
                <td>{patient.lastName}</td>
                <td>{patient.payor.indexOf("UnitedHealthcare")>=0?"UnitedHealthcare":patient.payor}</td>
                <td>
                <Form.Select aria-label="" value={bucketId || -1} onChange={onBucketChange}>
                    <option value={-1}> -- Select -- </option>
                    {
                        patient.buckets.map((bucket, id) => {
                            return (<option  key={id} value={bucket.id}>{bucket.name} - {100*bucket.minPercentage}%</option>);
                        })
                    }
                </Form.Select>
                </td>
            </tr>

        </>
    );
}

export default PatientActionItem;
