import React, {useState} from "react";
import { Button, Col, Alert } from 'react-bootstrap';
import { TitledHeader } from "../headers";
import Form  from 'react-bootstrap/Form';
import { useNavigate  } from "react-router-dom";
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { post } from "react-restfully";
import {LoadingSpinner, UserContext, CheckUserAccess } from '..';
import $ from 'jquery';

function SignIn(props) {
    const allowSignUp = false;
    
    let remembered = localStorage.getItem("react-login-email") || "";
    const navigate = useNavigate();
    const [didLogin, setDidLogin] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [email, setEmail] = useState(remembered);
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(remembered!="");
      
    
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        const valid = form.checkValidity();
        setValidated(true);
        
        if (valid) {         
            setLoginError(null);
            setLoading(true);
            
            if (rememberMe) {
                localStorage.setItem("react-login-email", email)
            } else {
                localStorage.removeItem("react-login-email")
            }
            
            post('/api/authenticate', {
                payload: {
                    username: email,
                    password: password
                },
            }).then(data => {
                setDidLogin(true);
                setLoading(false);
                userContextLocal.setUser(data);
                console.dir(data);
                
                const hasAccess = CheckUserAccess();
                console.log("hasAccess: "+hasAccess);
                if (!hasAccess) {
                    console.log("NO FREAKING hasAccess: ");
                    setDidLogin(false);
                    clearUserData();
                    const enterpriseName = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-name').replaceAll('"', '');
                    setLoginError("You do not have permission to access "+enterpriseName);
                } else {
                    let url = localStorage.getItem("signin_from");
                    if (url==null || url.length==0) {
                        url = "/";
                    }
                    localStorage.removeItem("signin_from");
                    console.log(" hasAccess !!  url: "+url);
                    navigate(url, {replace: true});
                }
            }).catch(err =>{
                setLoginError("You have entered an invalid username or password");
                clearUserData();   
                setLoading(false);
            });
        }
    };
    
    const clearUserData = () => {
        if (!didLogin) {
            userContextLocal.setUser(null);   
        }
    }
    
    const showAlert = () => {
        if (loginError) {
            return (
                <Alert variant='danger'>
                    {loginError}
                </Alert>
            );
        }
    }
    
    const toggleRememberMe = (evt) => {
        setRememberMe(evt.target.checked);
    }
    
    const clickLink = (url, evt) => {
        navigate(url);
        if (evt) {
            evt.preventDefault();
        }
    }

    let userContextLocal = null;
    const signInCover = "url("+getComputedStyle(document.documentElement).getPropertyValue('--signin-background')+")";

    
    const renderSignUp = () => {
        if (allowSignUp) {
            return (
                <>
                    <div className="mb-3 center">
                        <div className="separator" style={{width: "80px"}}></div>
                    </div>
                    
                    <div className="center">
                        Not a member? <a href="#" onClick={ (evt) => {clickLink("/users/sign_up", evt)}}>Sign up now</a>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="mb-3 center">
                        <div className="separator" style={{width: "80px"}}></div>
                    </div>
                    
                    <div className="center">
                        Not a member? <a href="#" onClick={ (evt) => {clickLink("/contact-us", evt)}}>Contact Us</a>
                    </div>
                </>
            )
        }
        return <></>;
    }
    
    return (
    <UserContext.Consumer>
    {
        (userContext) => 
        {
            userContextLocal = userContext;
            if (userContext.user) {
                clearUserData();
            }
            return (
                <>
                    <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
                    <div className="signin-cover" style={{backgroundImage: signInCover}}></div>
                    <div className="filled">
                        <div className="signin">
                            <div className="container">
                                <div className="signin-container">
                                    <div className="signin-form">
                                        <Form noValidate validated={validated}>
                                            <h3>Login</h3>
                                            
                                            {
                                                showAlert()
                                            }
                                            <Form.Group controlId="email" className="mb-3">
                                                <Form.Control required type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value )} />
                                                <Form.Control.Feedback type="invalid">Please provide a email address.</Form.Control.Feedback>
                                            </Form.Group>
                                            
                                            <Form.Group controlId="password" className="mb-3">
                                                <Form.Control required type="password" placeholder="Password" minLength="6" value={password} onChange={e => setPassword(e.target.value )}/>
                                                <Form.Control.Feedback type="invalid">Password must be at least 6 characters.</Form.Control.Feedback>
                                            </Form.Group>
                                            
                                            <div className="mb-2">
                                                <div className="float-left">
                                                    <Form.Check id="remember" label="Remember Me" checked={rememberMe} onChange={e => {toggleRememberMe(e)}}/>
                                                </div>
                                                
                                                <div className="float-right">
                                                    <a href="#" onClick={ (evt) => {clickLink("/users/password/new", evt)}}>Forgot your password?</a>
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="mb-2">
                                                <button className={"button-action"} onClick={(evt) => {handleSubmit(evt);}}>Login</button>
                                            </div>
                                            
                                            {renderSignUp()}
                                            
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
    </UserContext.Consumer>
    );
  
}

export default SignIn;
