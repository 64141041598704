import React, { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { TitledHeader } from "../headers";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from "react-router-dom";


function PrivacyPolicy() {
    return (
        <>
        <TitledHeader title="Privacy Policy"></TitledHeader>
            <div className="body-content">
                <div className="container">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry&apos;s standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.
                    </p>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
