import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import { ImageOverlay } from "..";

function ClaimOfferComment(props) {
  const comment = props.comment;
  const navigate = useNavigate();
  const [imageVisible, setImageVisible] = useState(false);
  
  const showImage = (evt) => {
    setImageVisible(true)
  }

  const hideImage = () => {
    setImageVisible(false)
  }

  const outputVisibleImage = () => {
    if (imageVisible) {
      console.log("outputing visible")
      let src = "data:image/png;base64, "+comment.confirmationImage;
      return (
        <>
          <ImageOverlay src={src} onClose={hideImage}></ImageOverlay>
        </>
      )
    }

    return <></>
  }

  const outputImage = () => {
    if (comment.confirmationImage && comment.confirmationImage.length) {
      return (<FontAwesomeIcon icon="image" style={{fontSize: "36px", color: "green", cursor: "pointer" }}  onClick={showImage}/>);
    }
    return <></>;
  }

  return (
    <>
      {outputVisibleImage()}
      <tr>
        <td>Counter</td>
        <td>
          <FormattedDate value={comment.commentDtm} month='numeric' day='numeric' year='numeric'/>&nbsp;
          <FormattedTime value={comment.commentDtm} hour="numeric" minute="numeric"/>
        </td>
        <td>${parseFloat(comment.counterAmount).toFixed(2)}</td>
        <td>{comment.status}</td>
        <td style={{textAlign: "center"}}>{outputImage()}</td>
      </tr>
    </>
  );
}

export default ClaimOfferComment;
