import React, {useState, useContext} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

function LoadingSpinner(props) {
    if (!props.active) {
        return <></>;
    }
  return (
    <div className="loading-overlay">
        <div className="spinner">
            <FontAwesomeIcon icon={faSpinnerThird}  fixedWidth className="fill" />
        </div>
    </div>
  );
}

export default LoadingSpinner;
