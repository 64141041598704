import React from "react";


import {
    useQuery,
    gql
  } from "@apollo/client";
  
function HomeCharts() {
  /*  const HOME_BANDS = gql`
    {
        homeBandCategories {
          category
          title
          url
          items {
            category
            image
            title
            subtitle
            body
            url
            date
            bodyIsHtml
          }
        }
      }`;
    const { loading, error, data } = useQuery(HOME_BANDS);
    
    let homeBands = [];
    if (error) {
        return <></>;
    } else if (loading) {
        homeBands = [
            {
                title: 'Newest Solutions'
            },
            {
                title: 'Trending Knowledge'
            },
            {
                title: 'Latest News'
            }
        ]
    } else {
        homeBands = data.homeBandCategories;
    }*/
    
  return (
    <div>
    </div>
  );
}

//

export default HomeCharts;
