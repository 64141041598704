import React, {useState} from "react";
import { useNavigate  } from "react-router-dom";
import { TitledHeader } from "../headers";
import { Button, Col, Alert } from 'react-bootstrap';
import Form  from 'react-bootstrap/Form';
import { TitledPageChange, GetUser } from '..';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReCAPTCHA from "react-google-recaptcha";
import { post } from "react-restfully";
import {LoadingSpinner, UserContext} from '..';
import $ from 'jquery';

function ChangePassword() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState("");
    const [submitStatusState, setSubmitStatusState] = useState("danger");
    
    const handleSubmit = (event) => {
        var oldpassword = $("#oldpassword");
        var password = $("#formpassword");
        var password2 = $("#formpassword2");
        password2[0].setCustomValidity("");
        
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        if (password.val() !== password2.val()) {
            password2[0].setCustomValidity("Passwords do not match")
            valid = false;
        } else {
            password2[0].setCustomValidity("")
        }
        
        setValidated(true);
  
        if (valid) {         
            let body =  {
                "current_password":  oldpassword.val(), 
                "new_password":  password.val(), 
                "new_password_confirmation":  password2.val()
            };
            
            setSubmitStatus(false);
            setLoading(true);
            post('/api/secure/updatepassword', {
                payload: body,
                headers: 
                    {
                        "Authorization": "Bearer "+GetUser().jwttoken,
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {
                    setSubmitStatusState("success");
                    setSubmitStatus("Your password has been updated.");
                    oldpassword.val("");
                    password.val("");
                    password2.val("");
                    setValidated(false);
                } else {
                    setSubmitStatusState("danger");
                    setSubmitStatus(data.message);
                }
                
                setLoading(false);
            }).catch(err =>{
                setSubmitStatusState("danger");
                setSubmitStatus("Unknown Error");
                setLoading(false);
            });
        }
    };
    
    const showAlert = () => {
        if (submitStatus && submitStatus.length) {
            return (
                <Alert variant={submitStatusState}>
                    {submitStatus}
                </Alert>
            );
        }
    }
    
    const signInCover = "url("+getComputedStyle(document.documentElement).getPropertyValue('--signin-background')+")";
  return (
        <>
        <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
        <  div className="signin-cover" style={{backgroundImage: signInCover}}></div>
            <div className="filled">
                <div className="signin">
                    <div className="container">
                        <div className="signin-container">
                            <div className="signin-form">
                                <Form noValidate  validated={validated} onSubmit={(evt) => {handleSubmit(evt);}}>
                                    <h3>Change Your Password</h3>
                                        {
                                            showAlert()
                                        }
                                    
                                    <Form.Group controlId="oldpassword" className="mb-3">
                                        <Form.Control required type="password" placeholder="Old Password" minLength="6"/>
                                        <Form.Control.Feedback type="invalid">Password must be at least 6 characters.</Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group controlId="formpassword" className="mb-3">
                                        <Form.Control required type="password" placeholder="New Password" minLength="6"/>
                                        <Form.Control.Feedback type="invalid">Password must be at least 6 characters.</Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group controlId="formpassword2" className="mb-3">
                                        <Form.Control type="password" placeholder="New Password (again)"/>
                                        <Form.Control.Feedback type="invalid">Passwords must match.</Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    
                                    <div className="mb-3">
                                        <button className={"button-action"}>Update Password</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
}

export default ChangePassword;
