import React from "react";
import { HomeCharts, PatientActionItems, ActiveClaims } from "..";


import {
    useQuery,
    gql
  } from "@apollo/client";
  
function Patients() {
    
  return (
    <>
        <div>
            <div className="body-content">
                <div className="container">
                    <PatientActionItems allPatients={true}/>
                </div>
            </div>
        </div>
    </>
  );
}

//

export default Patients;
