import React, { useState } from "react";

import { BucketSettings } from "..";

import {
    useQuery,
    gql
} from "@apollo/client";

function ProviderSettings(props) {
    const onUpdate = props.onUpdate;
    const provider = props.provider;
    const [defaultBucket, setDefaultBucket] = useState(provider.defaultBucketName);
    
    const onBucketUpdate = (bucket, applyToAll, defaultBucketNew) => {
        console.log("defaultBucketNew: "+defaultBucketNew)
        setDefaultBucket(defaultBucketNew);
        if (onUpdate) {
            onUpdate(bucket, applyToAll, defaultBucketNew);
        }
    }
    
    console.dir(provider)
    console.log("PROVIDER DEF BUCKET: "+defaultBucket);

    return (
        <>
            <h3 >{provider.name}</h3>
            <p><strong>Notifications Email: </strong>{provider.fullEmail}</p>
            <p><strong>EIN: </strong>{provider.einLastFour}</p>
            <p><strong>Default Bucket: </strong>{defaultBucket||"N/A"}</p>
            <div className="mb-5">
                <table className="table table-striped" style={{maxWidth: "500px"}}>
                    <thead>
                        <tr>
                            <th scope="col">Insurance Class</th>
                            <th scope="col">Minimum Offer Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            provider.buckets.map((bucket, id) => {
                                return (<BucketSettings bucket={bucket} defaultBucketName={defaultBucket} key={id} onUpdate={onBucketUpdate}/>);
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

//

export default ProviderSettings;
