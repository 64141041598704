import React, {useState} from "react";
import { Button, Col, Alert } from 'react-bootstrap';
import { TitledHeader } from "../headers";
import Form  from 'react-bootstrap/Form';
import { useNavigate  } from "react-router-dom";
import InputGroup  from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { post } from "react-restfully";
import {LoadingSpinner, UserContext} from '..';
import $ from 'jquery';

function SignOut() {
    const navigate = useNavigate();
    let context = null;
    
    React.useEffect(() => {
        context.setUser(null);   
        setTimeout(() => {
            navigate("/");
        }, 1);
    });
    
  return (
    <UserContext.Consumer>
    {
        (userContext) => 
        {
            context = userContext;
        }
    }
    </UserContext.Consumer>
    );
}

export default SignOut;
