import React, { useState } from "react";
import {sortByAttributes} from "../utils/sort.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ActiveClaim, LoadingSpinner } from "..";
import { normalizeClaims } from "./NormalizeClaims.js";

import {
    useQuery,
    gql
  } from "@apollo/client";
  
function ActiveClaims(props) {
    const searchText = props.searchText;
    const sortBy = props.sortBy;
    const showExpired = props.showExpired;

    const ACTIVE_CLAIMS = gql`
        query activeClaims($criteria: String) {
            activeClaims (criteria: $criteria) {
                id
                name
                active
                ein
                processingType
                calculationType
                buckets {
                  id
                  name
                  minPercentage
                  active
                  canEdit
                }
                claims {
                    id
                    dos
                    dosLong
                    multiplanPatient
                    multiplanAccount
                    multiplanClaim
                    payor
                    payorClaim
                    multiplanContact
                    multiplanPhone
                    billedCharges
                    initialOffer
                    acceptedOffer
                    status
                    active
                    createUser
                    createDtmEpoch
                    lastUpdateUser
                    lastUpdateDtmEpoch
                    webkey
                    expiringStatus
                    expiringDateLong
                    
                    hasEmrClaimMatch
                    minimumOffer
                    targetOffer
                    calculationDateLong

                    latestOffer {
                        id
                        amount
                        status
                        offerDtmEpoch
                        fee
                        feeStatus
                        acceptedDtmEpoch
                        acceptedSignature
                        rejectedDtmEpoch
                        firstName
                        lastName
                        jobTitle
                        phone
                        emailAddress
                        hasConfirmationImage
                        latestComment {
                            id
                            counterAmount
                            status
                            commentDtmEpoch
                            sendCommentDtmEpoch
                        }
                    }
                    offers {
                        id
                        amount
                        status
                        offerDtmEpoch
                        fee
                        feeStatus
                        acceptedDtmEpoch
                        acceptedSignature
                        rejectedDtmEpoch
                        firstName
                        lastName
                        jobTitle
                        phone
                        emailAddress
                        hasConfirmationImage
                        latestComment {
                            id
                            counterAmount
                            status
                            commentDtmEpoch
                            sendCommentDtmEpoch
                        }
                    }
                    patient {
                        id
                        firstName
                        lastName
                        payor
                        bucketId
                        active
                    }
                }
            }
        }`;

    const searchCriteria = {
        search: searchText,
        showExpired: showExpired
    }
    const { loading, error, data } = useQuery(ACTIVE_CLAIMS, {variables: {"criteria": JSON.stringify(searchCriteria)}});
    
    if (loading) {
        return (            
            <LoadingSpinner active={true} text="test loader" className="loading-overlay"></LoadingSpinner>
        )
    }
    if (error || loading) {
        return <></>;
    }

    
    let providerClaims = [];
    providerClaims = data.activeClaims;

    let claimsAndBuckets = normalizeClaims(providerClaims);
    
    let claims = claimsAndBuckets.claims;
    let buckets = claimsAndBuckets.buckets;
    
    sortByAttributes(claims, sortBy);
    
    if (claims.length==0) {
        return (<h3 className="mt-3">No Claims Found</h3>);
    }
    
  return (
    <>
        <table className="table table-striped w-auto">
            <thead>
                <tr>
                    <th scope="col" style={{textAlign: "center"}}><FontAwesomeIcon style={{color: "red", fontSize: "28px"}} icon="triangle-exclamation" /></th>
                    <th scope="col">Expiration Date</th>
                    <th scope="col">
                        Payor/Claim/MP/Account
                    </th>
                    <th scope="col">Provider</th>
                    <th scope="col">Patient / DOS</th>
                    <th scope="col">Billed</th>
                    <th scope="col">Minimum</th>
                    <th scope="col">Target</th>
                    <th scope="col">Current Offer</th>
                    <th scope="col">Counter</th>                    
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    claims.map((claim, id) => {
                        return (<ActiveClaim claim={claim} key={id} />);
                    })
                }
            </tbody>
        </table>
    </>
  );
}

//

export default ActiveClaims;
