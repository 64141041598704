import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import InputGroup from 'react-bootstrap/InputGroup';
import { faChevronRight, faChevronDown, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import { ActiveClaims } from "..";

import { post } from "react-restfully";
import { LoadingSpinner, GetUser } from "..";

function AddWebkey(props) {
    const [loading, setLoading] = useState(false);
    const [webkey, setWebkey] = useState(props.webkey ?? "");
    const webkeyRef = React.createRef();

    const valueChanged = (evt) => {
        setWebkey(evt.target.value);
    }
    const refreshAll = (e) => {
        e?.preventDefault();
        
        let body =  {
            "refresh": true
        };
        
        setLoading(true);
        post("/api/secure/refresh-claims", {
            payload: body,
            headers: 
                {
                    "Authorization": "Bearer "+GetUser().jwttoken,
                    "Content-Type": "application/json"
                }
        }).then(data => {
            console.dir(data);
            if (data.status===0) {
                window.alert("Refresh all claims queued.");
            }
            
            setLoading(false);
        }).catch(err =>{
            window.alert("Error refreshing all");
            setLoading(false);
        });
    }
    const onSubmit = (e) => {
        e?.preventDefault();
        let aWebkey = $(webkeyRef.current).val().trim();

        setWebkey(aWebkey);

        if (aWebkey.length==0) return;
        
        let body =  {
            webkey: aWebkey
        };
        
        setLoading(true);
        post("/api/secure/add-webkey", {
            payload: body,
            headers: 
                {
                    "Authorization": "Bearer "+GetUser().jwttoken,
                    "Content-Type": "application/json"
                }
        }).then(data => {
            if (data.status===0) {
                window.alert("Webkey added.  Processing will occur soon.");
            }
            
            setWebkey("");
            setLoading(false);
        }).catch(err =>{
            window.alert("Error adding webkey");
            setLoading(false);
        });
    }

    return (
        <>
        <LoadingSpinner active={loading} text="test loader" className="loading-overlay"></LoadingSpinner>
            <div className="container search-header" style={{ marginBottom: "10px" }}>
                <Form className="category-search form-inline" onSubmit={(e) => { onSubmit(e); }} style={{ display: "flex" }}>
                    <Form.Group>
                        <InputGroup >
                            <Form.Control className="input-no-outline"
                                type="text"
                                placeholder={"Webkey"}
                                defaultValue={webkey}
                                value={webkey}
                                onChange={valueChanged}
                                ref={webkeyRef}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Button variant="repricer" style={{ marginLeft: "5px" }} onClick={(e) => { onSubmit(e); }}>Add Webkey</Button>
                    <Button variant="repricer" style={{ marginLeft: "5px" }} onClick={(e) => { refreshAll(e); }}>Refresh All</Button>
                </Form>
            </div>
        </>
    );
}

//

export default AddWebkey;
